import logo from '../assets/logo2.png';
import x from '../assets/x.png';
import facebook from '../assets/facebook.png';
import instagram from '../assets/instagram.png';
import youtube from '../assets/youtube.png';
import call from '../assets/call.png';
import support from '../assets/mail.png';
import location from '../assets/location.png';
import { Link } from 'react-router-dom';

function footer() {
	return (
		<div className='flex flex-col justify-center footer md:h-96 w-full bg-black mt-16 px-8'>
			<div className='md:flex'>
				<div className='md:w-1/4 w-full md:pt-0 pt-4'>
					<div className='flex'>
						<img src={logo} className='w-10 h-10 ' alt='logo' />
						<p className='logo2 text-white text-lg ml-1'>Buy Made In Afrika</p>
					</div>

					<div className='flex mt-8 gap-1'>
						<a href='https://twitter.com/BuymadeinAfrika'>
							<img src={x}></img>
						</a>
						<a href='https://www.facebook.com/profile.php?id=61553388146379'>
							<img src={facebook}></img>
						</a>

						<a href='https://www.instagram.com/buymadeinafrika/'>
							<img src={instagram}></img>
						</a>

						<img src={youtube}></img>
					</div>
					<p className='text-white md:w-4/5 w-full mt-6 md:mt-0 hidden md:block'>
						Copyright © 2024 Buy Made In Afrika - All Rights Reserved.
					</p>
				</div>
				<div className='md:w-1/4 w-1/2 footer-color'>
					<h1 className='md:mb-12 mb-2 text-white pt-2 md:pt-0'>Product</h1>

					<div className=' flex flex-col'>
						<Link to={'/delete'} className='mb-2'>
							Delete Account
						</Link>
						<Link to={'/block'} className='mb-2'>
							Block User
						</Link>
						<Link to={'/privacy'} className='mb-2'>
							Privacy Policy
						</Link>
						<Link to={'/account'} className='mb-2'>
							Account
						</Link>
					</div>
				</div>
				<div className='w-1/4 footer-color'>
					<h1 className='md:mb-12 mb-2 text-white pt-2 md:pt-0'>Quick Links</h1>
					<div className=' flex flex-col'>
						<Link to={'/'} className='mb-2'>
							Home
						</Link>
						<Link to={'/Sellmia'} className='mb-2'>
							Sell MIA
						</Link>
						<Link to={'/about'} className='mb-2'>
							About Us
						</Link>
						<Link to={'/contact'} className='mb-2'>
							Contact Us
						</Link>
					</div>
				</div>
				<div className='w-1/4 footer-color mt-12 md:mt-0'>
					<h1 className='md:mb-12 mb-2'>Reach us Via</h1>
					<div className='flex items-center'>
						<img className='mr-3 w-4 h-4' src={support}></img>
						<h1 className='mb-2 text-base'>support@buymadeinafrika.com</h1>
					</div>
					<div className='flex mt-4 items-center'>
						<img className='mr-3 h-4 w-4' src={call}></img>
						<h1 className='mb-2 text-base'>08135525183</h1>
					</div>
					<div className='flex mt-4 items-center'>
						<img className='mr-3 h-4 w-4' src={location}></img>
						<h1 className='mb-2 text-base'>United Kingdom</h1>
					</div>
				</div>
			</div>
			<p className='text-white md:w-4/5 w-full mt-6 md:mt-0 block md:hidden'>
				Copyright © 2024 Buy Made In Afrika - All Rights Reserved.
			</p>
		</div>
	);
}

export default footer;
