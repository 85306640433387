import newsletter from '../assets/newsletter.png';
import notificationIcon from '../assets/sms-notification.png';
import newsLetterIcon from '../assets/input-leading-icon.png';

function newsLetter() {
	return (
		<div className='md:flex newsletter'>
			<div className='md:h-full md:flex md:flex-col md:w-1/2 md:justify-center md:pl-16 '>
				<h1 className='font-bold md:text-3xl md:mb-8 mx-4 md:mx-0 text-xl mb-2 md:text-center md:text-left'>
					Sign up for our newsLetter
				</h1>
				<h1 className='md:text-sm md:w-4/5 mx-4 md:mx-0 text-xs md:text-center md:text-left'>
					Our unique solution combines AI technology, customization, and
					strategic partnerships to cater to the needs of African immigrants and
					expatriates in Europe and North America.
				</h1>
				<div className='flex rounded-full bg-gray-100 p-4 items-center justify-between md:w-1/2 w-3/4 mt-16 mx-4 md:mx-0'>
					<img src={notificationIcon}></img>
					<input
						type='text'
						className='bg-gray-100 text-xs ml-2 w-full h-8 enabled:hover:border-gray-400'
						placeholder='Enter your email address here'
					></input>

					<img className='justify-end' src={newsLetterIcon}></img>
				</div>
			</div>
			<div className='md:w-1/2 w-full mt-8 md:mt-0'>
				<img src={newsletter} className='h-64 w-full md:h-full'></img>
			</div>
		</div>
	);
}

export default newsLetter;
