import React from 'react';
import { render } from 'react-dom';
import bg from '../assets/homebg.png';
import icon from '../assets/d-arrow.png';
import ordersImg from '../assets/orders-img.png';
import NewsLetter from '../navigation/newsletter';
import Footer from '../navigation/footer';
import SellMia from '../component/sellMiaButton';
import SellMiaIntro from '../component/sellMia';
import BuyMiaButton from '../component/buyMiaButton';

function SellMIA() {
	let appLinks = ['https://apps.apple.com/ng/app/sell-mia/id1639711519'];
	return (
		<div className='w-full mr-8 h-full md:mt-0 mt-8'>
			<div className='hidden md:block'>
				<div
					className='relative bg-right w-full mia-h-96 bg-no-repeat mr-8'
					style={{ backgroundImage: `url(${bg})`, display: 'hidden' }}
				>
					<div className='absolute top-20 left-12 product'>
						<div className='flex flex-col justify-between h-full'>
							<h1 className='mb-4'>
								Sell your product to a Global market with Sell MIA App
							</h1>
							<h4>
								Unlock the full potential of our revolutionized Fashion App that
								leverage AI technology to provide a seamless digital fashion
								experience and shopping.
							</h4>
							<div className='md:flex w-40 md:w-full gap-2 items-center'>
								<p className='italic mb-1'>Get it on Appstore</p>
								<BuyMiaButton
									title='Download SellMIA'
									downloadLink={appLinks[0]}
								></BuyMiaButton>
							</div>
						</div>
					</div>
					<div className='absolute right-36 top-40'>
						<img src={ordersImg}></img>
					</div>
				</div>
			</div>

			<div className='md:hidden mx-4	'>
				<div className='product'>
					<div className='mx-4'>
						<h1 className=''>
							Sell your product to a Global market with Sell MIA App
						</h1>
						<h4 className='mt-4 mb-4'>
							Unlock the full potential of our revolutionized Fashion App that
							leverage AI technology to provide a seamless digital fashion
							experience and shopping.
						</h4>
						<p className='italic'>Get it on Appstore</p>
						<div className='w-1/2'>
							<BuyMiaButton title='Download SellMIA'></BuyMiaButton>
						</div>
					</div>
				</div>
				<div className='mt-8 mx-4'>
					<img src={ordersImg}></img>
				</div>
			</div>
			<SellMiaIntro></SellMiaIntro>
			<div className='flex bg-black h-80 md:w-5/6 items-center rounded-xl md:m-auto mt-8 mx-4'></div>
			<div className='md:m-auto md:w-2/5 mt-8 md:mb-16 mx-8'>
				<h1 className='mb-4 md:text-4xl text-xl font-bold md:mt-4 md:text-center'>
					Innovation meets traditions
				</h1>
				<h4 className='text-xs md:text-center text-left mb-4'>
					Our unique solution combines AI technology, customization, and
					strategic partnerships to meet the fashion needs of Africans and
					African fashion enthusiasts globally.
				</h4>

				<div className='mx-auto'>
					<p className='md:text-center italic mb-2'>Get it on Playstore</p>
					<SellMia></SellMia>
				</div>
			</div>

			<NewsLetter></NewsLetter>
			<Footer></Footer>
		</div>
	);
}

export default SellMIA;
