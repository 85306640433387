import icon from '../assets/d-arrow.png';
function BuyMiaButton({ downloadLink, title }) {
	return (
		<div className='flex gap-2 bg-red-500 rounded-full md:w-48 px-4 py-3  md:p-4 mt-4 md:mt-0'>
			<img className='w-4 h-4' src={icon}></img>
			<button className='text-white items-start text-xs font-bold'>
				<a href={downloadLink}>{title}</a>
			</button>
		</div>
	);
}

export default BuyMiaButton;
