import Qa from '../assets/qa.png';
import arrowUp from '../assets/arrow-up.png';
import arrowUpInactive from '../assets/arrow-up-black.png';

function MiaFAQ() {
	return (
		<div className='h-full  w-full pt-8 md:px-16'>
			<div className='md:w-2/5 text-center md:m-auto mb-16 px-4'>
				<h1 className='mb-4 text-xl font-bold mt-4 pt-8'>
					Frequent Asked Questions
				</h1>
				<h4 className='text-sm text-center mt-4 mb-4'>
					These are the most commonly asked questions about Buymadeinafrika.
					Cant find what you’re looking for ? Chat to our friendly team.
				</h4>
			</div>
			<div className='mt-8 md:flex'>
				<div className='bg-gray-50  md:w-1/4 opacity-57 h-56 px-8 py-4 text-sm'>
					<div className='flex mb-4 gap-2'>
						<div>
							<img src={Qa}></img>
						</div>
						<p>How does Ai work?</p>
					</div>
					<div className='flex mb-4 gap-2 bg-white rounded p-1'>
						<div>
							<img src={Qa}></img>
						</div>
						<p>Fixing downlaods</p>
					</div>
					<div className='flex mb-4 gap-2'>
						<div>
							<img src={Qa}></img>
						</div>
						<p>Return and crediting</p>
					</div>
					<div className='flex mb-4 gap-2'>
						<div>
							<img src={Qa}></img>
						</div>
						<p>Deliveries</p>
					</div>
					<div className='flex mb-4 gap-2'>
						<div>
							<img src={Qa}></img>
						</div>
						<p>How to earn on SellMIA</p>
					</div>
				</div>
				<div className='md:ml-16 w-full pt-2'>
					<div>
						<div className='flex justify-between border-b w-full pb-4 mb-4'>
							<p>How can I download it?</p>
							<img src={arrowUp}></img>
						</div>
						<p className='text-xs'>
							Lorem ipsum dolor sit amet consectetur. Laoreet etiam sed maecenas
							eros. In ligula nulla adipiscing nunc ultricies dolor arcu. Duis
							lectus quam blandit netus egestas risus viverra sapien. Eleifend
							velit sed ullamcorper praesent. Tortor ultrices dui et ipsum.
							Adipiscing eget vestibulum diam enim nulla. Venenatis sit natoque
							tincidunt tristique ligula in. Viverra vehicula sit congue nec
							sapien risus. Convallis euismod amet rhoncus adipiscing ac lacus
							pellentesque facilisi. Vitae sed habitant tristique aliquam vitae
							sed lacinia. Mauris euismod sem suspendisse.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MiaFAQ;
