import cart from '../assets/cart.png';
import customize from '../assets/customize.png';
import tracking from '../assets/tracking.png';
import money from '../assets/money.png';

function SellMiaIntro() {
	return (
		<div>
			<div className='md:flex summary w-full mt-8 md:p-8 md:gap-2 md:space-x-4'>
				<div className=' px-4 text-center md:text-left pt-4 md:pt-0'>
					<img className='w-8 h-8 m-auto md:m-0' src={cart}></img>
					<h1 className='font-medium text-xl my-4'>Own a shop</h1>

					<p className='text-xs'>
						Own a Shop, Curate your boutique, connect with a global audience,
						and enjoy seamless transactions. Your shop, your style, your success
						– all in one app!"
					</p>
				</div>
				<div className='px-4 text-center md:text-left pt-4 md:pt-0'>
					<img className='w-8 h-8 m-auto md:m-0' src={tracking}></img>
					<h1 className='font-medium text-xl my-4'>Track your order</h1>
					<p className='text-xs'>
						Shop effortlessly with our fashion app's key feature: "Track your
						order." Monitor your purchase in real-time for a stress-free,
						transparent shopping experience.
					</p>
				</div>
				<div className='px-4 text-center md:text-left pt-4 md:pt-0'>
					<img className='w-8 h-8 m-auto md:m-0' src={customize}></img>
					<h1 className='font-medium text-xl my-4'>Easy Custimization</h1>
					<p className='text-xs'>
						Easily mix and match styles, colors, and sizes with just a few taps,
						ensuring a seamless and unique shopping experience.
					</p>
				</div>
				<div className='px-4 text-center md:text-left pt-4 md:pt-0'>
					<img className='w-8 h-8 m-auto md:m-0' src={money}></img>
					<h1 className='font-medium text-xl my-4'>Easy Cash Withdrawal</h1>
					<p className='text-xs'>
						Shop the latest trends effortlessly with our fashion app. Enjoy
						"Easy Cash Withdrawal" for seamless cash access. Elevate your style
						effortlessly.
					</p>
				</div>
			</div>
		</div>
	);
}

export default SellMiaIntro;
