import icon from '../assets/d-arrow.png';
function SellMia(url) {
	return (
		<div className='flex gap-2 bg-red-500 rounded-full md:w-40 px-4 py-3  md:py-2 md:m-auto w-1/2'>
			<img className='w-4 h-4' src={icon}></img>
			<button className='text-white items-start text-sm font-bold'>
				<a href='https://play.google.com/store/apps/details?id=com.madeinafrika.seller'>
					Download App
				</a>
			</button>
		</div>
	);
}

export default SellMia;
