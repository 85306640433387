import cloud from '../assets/cloud.png';
import dirtyimg from '../assets/dirtclothes.png';
import thinkerImg from '../assets/overthinker.png';
import mktIcon from '../assets/mkt-icon.png';
import aitechIcon from '../assets/aitech-icon.png';
import cartIcon from '../assets/cart-icon.png';
import solutionBg from '../assets/solution-bg.png';

function ProblemAndSolution() {
	return (
		<div>
			<div className='md:w-2/5 md:text-center md:m-auto md:mt-16 md:mb-16 mb-4 px-8'>
				<h1 className='mb-4 text-xl font-bold mt-4 pt-8'>
					The problem we are solving
				</h1>
				<h4 className='text-sm md:text-center mt-4 mb-4'>
					The Problem of Fast Fashion" explores the environmental and ethical
					challenges arising from the rapid, mass production of inexpensive
					clothing, emphasizing the need for sustainable alternatives in the
					industry.
				</h4>
			</div>
			<div className='md:flex md:mx-16 mx-8'>
				<div className='md:w-2/6'>
					<h3 className='font-semibold mb-2 text-sm'>
						Fast fashion generated more CO2 than aviation and shipping combined.
					</h3>
					<p className='mb-4 text-xs'>
						Fast fashion makes up to 10% of humanity’s carbon emissions, dries
						up water sources, and pollutes rivers and streams.
					</p>
					<img src={cloud} className='md:w-5/6 mb-6 md:mb-0'></img>
				</div>
				<div className='md:w-2/6'>
					<h3 className='font-semibold mb-2 text-sm'>
						Fashion's environmental crisis: unveiling the shocking reality of
						clothes waste.
					</h3>
					<p className='mb-4 text-xs'>
						We waste 92 million tons of clothing annually, equivalent to a
						truckload dumped in landfills every second. Clothing production
						doubled, but garment lifespan decreased by 36%.
					</p>
					<img src={dirtyimg} className='md:w-5/6 mb-6 md:mb-0'></img>
				</div>
				<div className='md:w-2/6'>
					<h3 className='font-semibold mb-2 text-sm'>
						Youth demographics and unemployment challenges
					</h3>
					<p className='mb-4 text-xs'>
						70% of Sub-Saharan African population is under the age of 30, while
						unemployment among youths in some countries is more than 50%
					</p>
					<img src={thinkerImg} className='md:w-5/6'></img>
				</div>
			</div>
			<div className='md:w-2/5 md:text-center md:m-auto md:mt-16 md:mb-16 mt-4 mx-8'>
				<h1 className='text-xl font-bold md:mt-4 md:pt-8'>The Solution</h1>
				<h1 className='mb-4 text-xl font-bold'>
					Buy Made In Afrika Promotes Slow Fashion
				</h1>
				<h4 className='text-sm md:text-center mt-4 mb-4'>
					Africa has a large pool of talented and creative designers and
					artisans but they lack access to global markets and customers
				</h4>
			</div>

			<div className='md:flex md:justify-between md:mx-8 gap-4'>
				<div className='md:w-1/2'>
					<div className='flex border-b gap-4 mx-8 items-center  py-8'>
						<img src={mktIcon} className='w-6 h-6'></img>
						<p className='text-sm pr-8'>
							Marketplace app that connects consumers with African fashion
							makers
						</p>
					</div>
					<div className='flex border-b gap-4 mx-8 items-center  py-8'>
						<img src={aitechIcon} className='w-6 h-6'></img>
						<p className='text-sm pr-8'>
							Uses a seamless AI technology that captures your accurate
							measurements just by taking 2 pictures of yourself thereby
						</p>
					</div>
					<div className='flex border-b gap-4 mx-8 items-center  py-8'>
						<img src={cartIcon} className='w-6 h-6'></img>
						<p className='text-sm pr-8'>
							Consumers can browse through a curated selection of products,
							choose their preferred fabrics, colors and designs, and place
							their orders directly with the makers.
						</p>
					</div>
				</div>

				<div className='px-4 mb-8 md:w-1/2'>
					<img
						src={solutionBg}
						className='md:w-3/4 mt-8 md:mt-0 px-3 md:px-0'
					></img>
				</div>
			</div>
		</div>
	);
}

export default ProblemAndSolution;
