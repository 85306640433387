import step1 from '../assets/deleteaccount1.webp';
import step2 from '../assets/deleteaccount2.webp';
import step3 from '../assets/deleteaccount3.webp';
import Footer from '../navigation/footer';
function HowToDeleteAccount() {
	return (
		<div>
			<h1 className='mx-8 mb-2 font-bold text-xl'>How to delete Account</h1>
			<div className='md:flex w-full mx-8 gap-4 text-sm'>
				<div className='md:w-2/6 w-full mb-4'>
					<img src={step1} className=''></img>
					<h1 className='font-bold my-2 text-xl'>Step 1.</h1>
					<p>
						Login to you buy made in africa account and locate the user profile
						icon at the top right conner as seen on the above image.
					</p>
				</div>
				<div className='md:w-2/6 w-full mb-4'>
					<img src={step2}></img>
					<h1 className='font-bold my-2 text-xl'>Step 2.</h1>
					<p>
						Click on profile settings as seen on the above image to proceed.
					</p>
				</div>
				<div className='md:w-2/6 w-full'>
					<img src={step3}></img>
					<h1 className='font-bold my-2 text-xl'>Step 3.</h1>
					<p>
						Locate and click on the delete account link as seen above, a popup
						would come up to confirm your action, confirm it and your account
						would be deleted.
					</p>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default HowToDeleteAccount;
