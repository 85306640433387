import step1 from '../assets/account1.webp';
import step2 from '../assets/account2.webp';
import step3 from '../assets/account3.webp';
import Footer from '../navigation/footer';
function Account() {
	return (
		<div className=''>
			<h1 className='mx-8 mb-2 text-xl font-bold'>How to delete account</h1>
			<div className='md:flex w-full mx-8 gap-4 text-sm'>
				<div>
					<img src={step1}></img>
					<h1 className='font-bold mb-1'>Step 1</h1>
					<p>Click on settings icon</p>
				</div>
				<div>
					<img src={step2}></img>
					<h1 className='font-bold mb-1'>Step 2</h1>
					<p>Click delete my account and data</p>
				</div>
				<div>
					<img src={step3}></img>
					<h1 className='font-bold mb-1'>Step 3</h1>
					<p>Click Yes</p>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default Account;
