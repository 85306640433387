import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import NavBar from './navigation/nav';
import BuyMIA from './pages/buymia';
import HowToDeleteAccount from './pages/delete';
import BlockUser from './pages/blockuser';
import PrivacyPolicy from './pages/privacy';
import Account from './pages/account';
import Blog from './pages/blog';
import SellMIA from './pages/home';
function App() {
	return (
		<div className='h-full'>
			<HashRouter>
				<NavBar></NavBar>
				<Routes>
					<Route path='/' element={<BuyMIA />}></Route>
					<Route path='/about' element={<About />}></Route>
					<Route path='/contact' element={<Contact />}></Route>
					<Route path='/sellmia' element={<SellMIA />}></Route>
					<Route path='/delete' element={<HowToDeleteAccount />}></Route>
					<Route path='/block' element={<BlockUser />}></Route>
					<Route path='/privacy' element={<PrivacyPolicy />}></Route>
					<Route path='/account' element={<Account />}></Route>
					<Route path='/blog' element={<Blog />}></Route>
				</Routes>
			</HashRouter>
		</div>
	);
}

export default App;
