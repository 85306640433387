import { Link } from 'react-router-dom';
import logo from '../assets/logo2.png';
import { useState } from 'react';
import openMenu from '../assets/menu.png';
import closeMenu from '../assets/closeMenu.png';

function NavBar() {
	const [showNav, setShowNav] = useState(false);
	const [isActive, setIsActive] = useState('Home');

	const links = ['Home', 'About', 'SellMIA', 'Event', 'Contact'];
	return (
		<>
			<div className='md:m-8 h-16'>
				<div className='flex justify-between'>
					<div className='flex md:mt-4 mt-8 ml-8 md:ml-0 w-full md:w-32'>
						<img src={logo} className='w-7 h-7 ' alt='logo' />
						<p className='logo'>Made in Afrika</p>
					</div>
					<div className='mt-4 flex gap-4 hidden md:block'>
						<div className='mynav flex gap-x-6'>
							<Link
								to={'./'}
								className='hover:rounded-full hover:text-red-400 hover:font-bold'
								style={{ color: isActive == 'Home' ? 'red' : '#a3a3a3' }}
								onClick={() => {
									setIsActive('Home');
								}}
							>
								Home
							</Link>
							<Link
								to={'./sellmia'}
								className='hover:rounded-full hover:text-red-400 hover:font-bold'
								style={{ color: isActive == 'SellMIA' ? 'red' : '#a3a3a3' }}
								onClick={() => {
									setIsActive('SellMIA');
								}}
							>
								SellMIA
							</Link>
							<Link
								to={'./blog'}
								className='hover:rounded-full hover:text-red-400 hover:font-bold'
								style={{ color: isActive == 'Event' ? 'red' : '#a3a3a3' }}
								onClick={() => {
									setIsActive('Event');
								}}
							>
								Event
							</Link>
							<Link
								to={'./about'}
								className='hover:rounded-full hover:text-red-400 hover:font-bold'
								style={{ color: isActive == 'About' ? 'red' : '#a3a3a3' }}
								onClick={() => {
									setIsActive('About');
								}}
							>
								About Us
							</Link>
							<Link
								to={'./contact'}
								className='hover:rounded-full hover:text-red-400 hover:font-bold'
								style={{ color: isActive == 'Contact' ? 'red' : '#a3a3a3' }}
								onClick={() => {
									setIsActive('Contact');
								}}
							>
								Contact
							</Link>

							<div>
								<button className='rounded-full border border-red-300 text-xs px-4 py-2 text-red-300'>
									<Link to={'./contact'} className='mr-2 font-bold'>
										Enquiry
									</Link>
								</button>
							</div>
						</div>
					</div>
					<div className='block md:hidden justify-end'>
						<div className='flex w-full justify-between'>
							<div className='flex justify-end'>
								<img
									style={{ display: !showNav ? 'block' : 'none' }}
									src={openMenu}
									className='w-8 h-8 justify-end mr-8 mt-8 cursor-pointer'
									onClick={() => {
										setShowNav(true);
									}}
								></img>
								<img
									style={{ display: showNav ? 'block' : 'none' }}
									src={closeMenu}
									className='w-8 h-8 justify-end mr-8 mt-8 cursor-pointer'
									onClick={() => {
										setShowNav(false);
									}}
								></img>
							</div>
						</div>
					</div>
				</div>
				<div
					className='absolute top-20 bg-white w-full z-40 h-full'
					style={{ display: showNav ? 'block' : 'none' }}
					onClick={() => {
						setShowNav(false);
					}}
				>
					<div className='flex flex-col pl-8 gap-4 underline mt-4 mynav'>
						<Link
							to={'./'}
							className='hover:rounded-full hover:text-red-400 hover:font-bold'
							style={{ color: isActive == 'Home' ? 'red' : '#a3a3a3' }}
							onClick={() => {
								setIsActive('Home');
							}}
						>
							Home
						</Link>
						<Link
							to={'./sellmia'}
							className='hover:rounded-full hover:text-red-400 hover:font-bold'
							style={{ color: isActive == 'SellMIA' ? 'red' : '#a3a3a3' }}
							onClick={() => {
								setIsActive('SellMIA');
							}}
						>
							SellMIA
						</Link>
						<Link
							to={'./blog'}
							className='hover:rounded-full hover:text-red-400 hover:font-bold'
							style={{ color: isActive == 'Event' ? 'red' : '#a3a3a3' }}
							onClick={() => {
								setIsActive('Event');
							}}
						>
							Event
						</Link>
						<Link
							to={'./about'}
							className='hover:rounded-full hover:text-red-400 hover:font-bold'
							style={{ color: isActive == 'About' ? 'red' : '#a3a3a3' }}
							onClick={() => {
								setIsActive('About');
							}}
						>
							About Us
						</Link>
						<Link
							to={'./contact'}
							className='hover:rounded-full hover:text-red-400 hover:font-bold'
							style={{ color: isActive == 'Contact' ? 'red' : '#a3a3a3' }}
							onClick={() => {
								setIsActive('Contact');
							}}
						>
							Contact
						</Link>
						<div>
							<button className='rounded-full border border-red-300 text-xs px-4 py-2 text-red-300'>
								<Link to={'./contact'} className='mr-2 font-bold'>
									Enquiry
								</Link>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default NavBar;
