import homeBg from '../assets/home-bg.png';
import Footer from '../navigation/footer';
import scope from '../assets/scope.png';
import ProblemAndSolution from '../component/problemAndsolution';
function About() {
	return (
		<div className=''>
			<div className='intro  w-full md:w-2/3 m-auto'>
				<h1>
					Empowering Sustainability and Celebrating the Diversity of African
					Fashion
				</h1>
				<p className='w-2/3 m-auto mt-4'>
					Unlock the full potential of the MadeinAfrika Apps that leverages AI
					technology to provide a seamless digital shopping experience
				</p>
			</div>
			<div className='mt-8 rounded'>
				<img src={homeBg} className='w-full px-8 rounded-full'></img>
			</div>
			<div className='intro md:w-2/3 m-auto mt-8'>
				<h1>Who Are We</h1>
				<p className='w-3/4 m-auto mt-4'>
					Our mission is to bridge the gap between African fashion and the
					diaspora by providing accessible, customizable, and sustainable
					clothing options. Our unique solution combines AI technology,
					customization, and strategic partnerships to cater to the needs of
					African immigrants and expatriates in Europe and North America.
				</p>
			</div>

			<div className='md:flex md:mx-16 md:h-96 md:gap-4 mt-8 mx-8'>
				<div className='vission md:w-1/2 w-full flex items-end'>
					<img src={scope} className='flex w-3/5 mx-auto h-2/3'></img>
				</div>
				<div className='md:w-1/2 w-full  flex flex-col h-full justify-around px-4'>
					<div>
						<h1 className='text-xl font-bold md:mb-2 mt-4 md:mt-0'>
							The Vision
						</h1>
						<ul className='text-xs list-disc'>
							<li className='mb-2'>
								Vision is to become the leading platform for slow fashion in the
								world, and to create a positive impact on the environment,
								society, and economy.
							</li>
							<li className='mb-2'>
								Aims to reduce the carbon footprint of the fashion industry by
								promoting sustainable and ethical practices, such as using
								organic and recycled materials, minimizing waste and pollution,
								and supporting fair trade and labor rights.
							</li>
							<li className='mb-2'>
								Also aims to empower African designers and artisans by providing
								them with access to a global market, increasing their income and
								livelihood, and showcasing their creativity and culture.
							</li>
							<li>
								Ultimately aims to create a community of conscious consumers and
								producers who share the same values and vision of slow fashion.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<ProblemAndSolution></ProblemAndSolution>
			<Footer></Footer>
		</div>
	);
}

export default About;
