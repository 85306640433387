function PrivacyPolicy() {
	return (
		<div className='mx-8'>
			<h1 className='text-xl font-bold mb-4'>Privacy Policy</h1>
			<div>This is our privacy policy page</div>
		</div>
	);
}

export default PrivacyPolicy;
