import React from 'react';
import SellMia from '../component/sellMiaButton';
import Hero from '../assets/Hero.png';
import customize from '../assets/customize2.png';
import cooperate from '../assets/cooperate.png';
import ai from '../assets/ai.png';
import store from '../assets/store.png';
import africa from '../assets/africa.png';
import shoe from '../assets/shoes.png';
import accessories from '../assets/accessories.png';
import africaclothes from '../assets/africaclothing.png';
import africafabrics from '../assets/afrifaric.png';
import influencer from '../assets/influencers.png';
import designers from '../assets/designers.png';
import img3 from '../assets/img33.png';
import img2 from '../assets/img2.png';
import myshoes from '../assets/myshoes.png';
import aipic from '../assets/aipic.png';
import img5 from '../assets/img5.png';
import icon from '../assets/d-arrow.png';
import homeMobile from '..//assets/home-mobile.png';
import shoesMobile from '..//assets/shoes-mobile.png';

import backIcon from '../assets/backicon.png';
import forwardIcon from '../assets/forwardicon.png';
import RatingCard from '../component/ratingCard';
import SellMiaIntro from '../component/sellMia';
import MiaFAQ from '../component/miaFAQ';
import NewsLetter from '../navigation/newsletter';
import Footer from '../navigation/footer';
import ProblemAndSolution from '../component/problemAndsolution';

import BuyMiaButton from '../component/buyMiaButton';
import { Link } from 'react-router-dom';

function BuyMIA() {
	let appLinks = [
		'https://apps.apple.com/ng/app/buy-made-in-afrika/id1640453099',
		'https://play.google.com/store/apps/details?id=com.madeinafrika.madeinafrica',
	];
	return (
		<div className='w-full'>
			<div className='intro md:w-2/3 m-auto'>
				<h1 className='mt-8 md:mx-0 mx-8'>
					A climate-friendly company that empowers African designers while
					celebrating the diversity of African
					<span className='text-red-400 ml-2'>fashion.</span>
				</h1>
				<p className='w-2/3 m-auto mt-4'>
					Unlock the full potential of the MadeinAfrika Apps that
					<span className='text-red-400 ml-1'> leverages AI technology</span> to
					provide a seamless digital shopping <span>experience</span>
				</p>
				<div className='flex md:justify-around mt-8 justify-center'>
					<div className='md:flex md:gap-4 items-center'>
						<span>Get it on Appstore</span>
						<BuyMiaButton
							downloadLink={appLinks[0]}
							title={'Download BuyMIA'}
						></BuyMiaButton>
					</div>
				</div>
			</div>
			<div className='mt-8 mx-16 md:block hidden'>
				<img src={Hero}></img>
			</div>
			<div className='mt-8 mx-16 md:hidden block'>
				<img src={homeMobile}></img>
			</div>
			<div className=' w-full px-8 summary'>
				<div className='m-auto md:w-2/5 mt-8 mb-16 w-full'>
					<h1 className='mb-4 text-4xl font-bold mt-4 pt-8'>
						Innovation meets traditions
					</h1>
					<h4 className='text-xs md:text-center mb-4'>
						Our unique solution combines AI technology, customization, and
						strategic partnerships to meet the fashion needs of Africans and
						African fashion enthusiasts globally.
					</h4>
				</div>
				<div className='md:flex  w-full mt-2 pb-8 md:gap-2 md:space-x-4'>
					<div className=' px-4'>
						<img className='w-8 h-8' src={ai}></img>
						<h1 className='font-medium text-xl my-4'>
							AI Measurement Technology
						</h1>
						<p className='text-xs'>
							Our AI-powered system generates accurate measurements from just
							two pictures, simplifying the process and ensuring a customized
							fit.
						</p>
					</div>
					<div className=' px-4 mt-6 md:mt-0'>
						<img className='w-8 h-8' src={customize}></img>
						<h1 className='font-medium text-xl my-4'>Customization</h1>
						<p className='text-xs'>
							Each product can be personalized to reflects your cultural
							identity, unique style and preferences.
						</p>
					</div>
					<div className=' px-4 mt-6 md:mt-0'>
						<img className='w-8 h-8' src={cooperate}></img>
						<h1 className='font-medium text-xl my-4'>
							Influencer Collaboration
						</h1>
						<p className='text-xs'>
							We collaborate with content creators to showcase our products, and
							they earn a percentage of sales generated through their
							promotions.
						</p>
					</div>
					<div className=' px-4 mt-6 md:mt-0'>
						<img className='w-8 h-8' src={store}></img>
						<h1 className='font-medium text-xl my-4'>Market Opportunity</h1>
						<p className='text-xs'>
							With our innovative solution, we tap into the underserved market,
							and create jobs for small and medium enterprises to provide a
							seamless shopping experience to you.
						</p>
					</div>
				</div>
			</div>
			<div className='w-full m-auto  mt-8'>
				<div>
					<img className=' m-auto' src={africa}></img>
				</div>
			</div>
			<div className='md:flex mx-8 mt-8 justify-between md:h-52 mb-16'>
				<div className='flex stats mb-8 md:mb-0 p-4 w-full text-xs md:w-2/6 justify-between md:justify-around md:items-center md:justify-center m-auto h-full'>
					<div>
						<div>
							<p className='mb-1 font-bold text-xl'>500+</p>
							<p>Satisfied Users</p>
						</div>
						<div className='mt-4'>
							<p className='mb-1 font-bold text-xl'>4.5+</p>
							<p>App Ratings</p>
						</div>
					</div>
					<div className=''>
						<div>
							<p className='mb-1 font-bold text-xl'>2k +</p>
							<p>Downloads</p>
						</div>
						<div className='mt-4'>
							<p className='mb-1 font-bold text-xl'>200</p>
							<p>Combine Market</p>
						</div>
					</div>
				</div>

				<div className='bg-white w-4/6 gap-4 md:flex md:flex-col justify-between h-full hidden'>
					<div className='flex gap-2 h-1/2'>
						<div className=''>
							<img src={africaclothes}></img>
						</div>
						<div className=''>
							<img src={shoe}></img>
						</div>
						<div className=''>
							<img src={designers}></img>
						</div>
					</div>
					<div className='flex gap-2 h-1/2'>
						<div className=''>
							<img src={africafabrics}></img>
						</div>
						<div className=''>
							<img src={accessories}></img>
						</div>
						<div className=''>
							<img src={influencer} />
						</div>
					</div>
				</div>

				<div className='md:hidden block'>
					<img src={shoesMobile}></img>
				</div>
			</div>
			<div className='afchamp md:w-1/2 text-center m-auto mt-8 mb-16 w-4/5'>
				<h1 className='mb-4 md:text-3xl font-bold mt-4 pt-8 text-2xl'>
					Welcome to Buy Made in Africa Fashion where innovation meets
					tradition.
				</h1>
				<h4 className='md:text-xs text-sm text-center mt-4 mb-4'>
					Our mission is to empower individuals to discover and embrace their
					own style, offering a seamless personalized shopping experience
					through cutting-edge artificial intelligence technology and
					celebrating the craftmanship of African designs in clothing, jewelry,
					shoes and bags.
				</h4>
				<div className='flex md:w-1/2 mx-auto'>
					<div className='mb-4 mx-auto md:mx-0 md:w-full w-1/2'>
						<div className='md:flex md:gap-3 md:items-center'>
							<span className='italic'>Get it on Playstore</span>
							<BuyMiaButton
								title={'Download BuyMIA'}
								downloadLink={appLinks[1]}
							></BuyMiaButton>
						</div>
					</div>
				</div>
			</div>
			<div className='md:w-4/5 md:flex md:gap-4 md:justify-around md:m-auto w-full'>
				<div className='md:w-2/6 w-full mb-6 md:mb-0'>
					<img
						src={img3}
						className='h-52 md:h-64 md:w-full w-full md:m-auto px-4'
					></img>
				</div>
				<div className='md:w-2/6 w-full mb-6 md:mb-0'>
					<img
						src={img2}
						className='h-52 md:h-64 md:w-full w-full md:m-auto px-4'
					></img>
				</div>
				<div className='md:w-2/6 mb-6 md:mb-0'>
					<img
						src={myshoes}
						className='h-52 md:h-64 md:w-full w-full md:m-auto px-4'
					></img>
				</div>
			</div>
			<div className='md:w-2/5 md:text-center md:m-auto mt-8 mb-16 mx-8'>
				<h1 className='mb-4 text-xl font-bold mt-4 pt-8'>
					Discover Asoebi & More
				</h1>
				<h4 className='text-sm md:text-center mt-4 mb-4'>
					We offer a wide range of clothing and accessories that are designed to
					help you express your individuality and feel confident in your own
					skin. From classic staples to bold statement pieces, we have
					everything you need to create a wardrobe that inspires you.
				</h4>
			</div>
			<div className='h-64 bg-black rounded-[13px] px-8 md:mx-16 mb-8 mx-8'></div>
			<div className='md:flex w-4/5 m-auto pt-16'>
				<div className='md:w-1/2'>
					<h1 className='font-bold text-xl'>AI Measurement</h1>
					<p className='text-sm  mt-2 mb-4'>
						Our AI-powered system generates accurate measurements from just two
						pictures – one from the front and another from the side –
						simplifying the process and ensuring a customized fit. This
						technology removes the barriers of measurement and offers a seamless
						shopping experience for our customers.
					</p>

					<h1 className='font-bold text-xl'>Uniquely Crafted For You</h1>
					<p className='text-sm  mt-2 mb-4'>
						Every individual piece is meticulously tailored to guarantee an
						impeccable fit on every occasion. The art of widespread
						customization has been honed over centuries in Africa, showcasing an
						exceptional expertise. Designers can concentrate on intricate
						handwork and artisanal craftsmanship, resulting in products of
						superior quality and lasting durability.
					</p>
				</div>
				<div className='w-1/2 flex justify-end'>
					<div className='w-3/5 relative'>
						<img src={aipic} className='h-full absolute'></img>
						<img src={img5} className='absolute h-3/4    top-32 left-44'></img>
					</div>
				</div>
			</div>

			<ProblemAndSolution></ProblemAndSolution>

			<div className='h-2/6 bg-black px-16 py-16'>
				<p className='text-white text-normal text-center opacity-75'>
					Testimonies
				</p>
				<h1 className='font-bold md:text-xl text-white md:text-center pt-2 text-sm'>
					Hear what Our Customers Are Saying
				</h1>
				<p className='text-white opacity-70 text-xs  md:text-center md:w-1/2 m-auto pt-4'>
					Discovering this fashion haven has been a game-changer for me! The
					unparalleled selection, trendsetting styles, and impeccable quality
					make every purchase a fashionista's dream come true.
				</p>
				<div className='flex justify-center w-1/2 m-auto mt-4'>
					<div>
						<img src={backIcon} className='h-6 w-6'></img>
					</div>
					<div>
						<img src={forwardIcon} className='h-6 w-6'></img>
					</div>
				</div>
				<div className='md:flex md:gap-3 w-full'>
					<RatingCard></RatingCard>
					<RatingCard></RatingCard>
					<RatingCard></RatingCard>
					<RatingCard></RatingCard>
				</div>
			</div>
			<div className='md:w-2/5 md:text-center md:m-auto mb-16 mx-4'>
				<h1 className='mb-4 text-2xl font-bold mt-4 pt-8 text-center'>
					Introducing SellMIA App.
				</h1>
				<h4 className='text-sm text-center mt-4 mb-4 w-full'>
					We offer a wide range of clothing and accessories that are designed to
					help you express your individuality and feel confident in your own
					skin. From classic staples to bold statement pieces, we have
					everything you need to create a wardrobe that inspires you.
					<span className='text-red-400 underline cursor-pointer pl-2'>
						<Link to={'./sellMia'}>See more</Link>
					</span>
				</h4>
			</div>

			<iframe
				className='md:h-96 bg-black rounded-[13px]  md:w-4/6 md:m-auto mb-8 h-64 mx-4'
				src='https://youtube.com/embed/ZjoYYL6rBNg'
			></iframe>

			<SellMiaIntro></SellMiaIntro>
			<div className='md:h-96 mb-16 rounded px-8'>
				<MiaFAQ></MiaFAQ>
			</div>
			<div className='pt-8'>
				<NewsLetter></NewsLetter>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default BuyMIA;
