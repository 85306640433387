import Footer from '../navigation/footer';
import NewsLetter from '../navigation/newsletter';
import contactUs from '../assets/contact-us.png';

function Contact() {
	return (
		<div className='w-full'>
			<div className='intro md:w-2/3 md:m-auto'>
				<h1>We’d love to be in touch</h1>
				<p className='md:w-2/3 m-auto mt-4'>
					We’d love to hear from you. If you’d prefer to email us instead, reach
					out to <span className='font-bold'>support@buymadeafrika.com</span>
				</p>
			</div>
			<div className='md:flex md:justify-between mt-8 md:mx-8 mb-8 mx-4'>
				<div className=''>
					<img src={contactUs} className='mx-4 md:mx-0'></img>
				</div>
				<div className='contact-form'>
					<div className='bg-white md:m-8 p-8  rounded-[13px]'>
						<h1 className='font-bold text-4xl mb-4'>Write us a note</h1>
						<p className='mb-2 text-sm'>What is your name?</p>
						<div className='flex rounded bg-gray-100 py-3 mb-8'>
							<input
								type='text'
								className='bg-gray-100 text-xs ml-2 w-full'
								placeholder=''
							></input>
						</div>
						<p className='mb-2 text-sm'>How about your email address?</p>
						<div className='flex rounded bg-gray-100 py-3 mb-8'>
							<input
								type='text'
								className='bg-gray-100 text-xs ml-2 w-full'
								placeholder=''
							></input>
						</div>
						<p className='mb-2 text-sm'>Subject</p>
						<div className='flex rounded bg-gray-100 py-3 mb-8'>
							<input
								type='text'
								className='bg-gray-100 text-xs ml-2 w-full'
								placeholder=''
							></input>
						</div>
						<p className='mb-2 text-sm'>What is your message?</p>
						<div className='flex rounded bg-gray-100 py-3'>
							<textarea
								rows={5}
								className='bg-gray-100 text-xs ml-2 w-full'
							></textarea>
						</div>

						<button className='text-center rounded-full border-2 border-red-200 p-3 mt-8 text-red-500 font-bold'>
							Send Message
						</button>
					</div>
				</div>
			</div>

			<NewsLetter></NewsLetter>
			<Footer></Footer>
		</div>
	);
}

export default Contact;
