import step1 from '../assets/blockuser1.webp';
import step2 from '../assets/blockuser2.webp';
import step3 from '../assets/blockuser3.webp';
import Footer from '../navigation/footer';
function BlockUser() {
	return (
		<div className=''>
			<h1 className='mx-8 mb-2 text-xl font-bold'>How to block a user</h1>
			<div className='md:flex w-full mx-8 gap-4 text-sm'>
				<div>
					<img src={step1}></img>
					<h1 className='font-bold mb-1'>Step 1</h1>
					<p>Click on the find more icon</p>
				</div>
				<div>
					<img src={step2}></img>
					<h1 className='font-bold mb-1'>Step 2</h1>
					<p>Search person</p>
				</div>
				<div>
					<img src={step3}></img>
					<h1 className='font-bold mb-1'>Step 3</h1>
					<p>Click on Block</p>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default BlockUser;
