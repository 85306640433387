import userAvatar from '../assets/userRating.png';
import star from '../assets/Star6.png';

function RatingCard() {
	return (
		<div className='mt-8 bg-gray-900 rounded-[14px] md:w-1/4 px-4 py-3'>
			<div className='flex gap-4'>
				<img src={userAvatar} className='h-12 w-12 mr-2'></img>
				<div className='text-white opacity-70'>
					<p className='font-bold text-sm'>Musa</p>
					<p className='text-xs'>Influencer</p>
				</div>
			</div>
			<div>
				<p className='text-white mt-4 text-xs'>
					We offer a wide range of clothing and accessories that are designed to
					help you express your individuality and feel confident in your own
					skin. From classic staples to bold statement pieces, we have
					everything you need to create a wardrobe that inspires you.
				</p>
			</div>
			<div className='mt-4 flex gap-2'>
				<img src={star}></img>
				<img src={star}></img>
				<img src={star}></img>
				<img src={star}></img>
				<img src={star}></img>
			</div>
		</div>
	);
}

export default RatingCard;
